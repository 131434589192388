import { useMemo } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  useCountdown,
  Typography,
  Button,
  ButtonVariants,
  TypographyVariants,
  BuyLuckyGameAccessModalProps
} from 'shared';
import { getLuckyGamePageUrl, getPathWithBaseUrl } from 'shared/lib/utils/url';
import { PartialLuckyGame } from 'shared/types/lucky-games.interfaces';

const TIME_LEFT_CLASSNAME = 'block transition-opacity transition-all duration-500';

interface Props extends PartialLuckyGame {
  handlePlayGame: ({ id }: BuyLuckyGameAccessModalProps) => void;
}

const LuckyGameCard = ({
  ended_at,
  logo_path,
  handlePlayGame,
  id,
  isParticipant,
  jackpot
}: Props) => {
  const timeLeft = useCountdown(ended_at);
  const isEnded = !timeLeft?.milliseconds;
  const navigate = useNavigate();

  const logoPath = getPathWithBaseUrl(logo_path);

  const handleClickPlay = () => handlePlayGame({ id });

  const handleNavigateToLuckyGamePage = () => {
    navigate(getLuckyGamePageUrl(id));
  };

  const buttonText = useMemo(() => {
    if (isEnded) return 'Ended';

    if (isParticipant) return 'View';

    return 'Join';
  }, [isEnded, isParticipant]);

  const buttonAction = useMemo(() => {
    if (isEnded) return undefined;

    if (isParticipant) return handleNavigateToLuckyGamePage;

    return handleClickPlay;
  }, [isEnded, isParticipant]);

  return (
    <div className="relative p-4 bg-[#282B30] rounded-xl">
      <div className="flex justify-end">
        <Typography className="flex">
          <span
            className={twMerge(
              cn(TIME_LEFT_CLASSNAME, 'h-6 w-auto opacity-100', {
                'w-0 opacity-0': isEnded
              })
            )}
          >
            {timeLeft?.fullDate}
          </span>
          <span
            className={twMerge(
              cn(TIME_LEFT_CLASSNAME, 'w-0 opacity-0', {
                'h-6 w-auto opacity-100': isEnded
              })
            )}
          >
            Ended
          </span>
        </Typography>
      </div>
      <div className="flex">
        <div className="w-4/12 flex flex-col items-center mt-2">
          <div>
            <img
              className="w-20 h-20 object-cover rounded-xl"
              src={logoPath}
              alt="lucky-game-logo"
            />
          </div>
          <Button
            onClick={buttonAction}
            className="mt-3.5 leading-5 p-1 w-full text-base"
            variant={ButtonVariants.PRIMARY}
            disabled={isEnded}
          >
            {buttonText}
          </Button>
        </div>
        <div className="w-8/12 text-center space-y-0.5 flex flex-col justify-center">
          <Typography tag="h6">Win the jackpot</Typography>
          <Typography className="text-2xl text-yellow" variant={TypographyVariants.LARGE_TITLE}>
            {jackpot}
          </Typography>
          <Typography>$COI</Typography>
        </div>
      </div>
    </div>
  );
};

export default LuckyGameCard;
