import { ReactComponent as CheckedIcon } from 'assets/svg/checked.svg';
import {
  asyncBoostActive,
  Button,
  Modal,
  selectUser,
  useAppDispatch,
  useAppSelector,
  useBoolean
} from 'shared';
import { InfoBlock } from 'shared/components/InfoBlock';
import { WithTitleBlock } from 'shared/components/WithTitleBlock';
import {
  BOOST_CLAIM_DETAILS,
  DOUBLE_BOOST_DETAILS,
  getUpgrades,
  UPGRADE_LEVEL_KEYS,
  UPGRADES_ICONS
} from 'shared/constants/boosters';
import { ButtonVariants } from 'shared/constants/button';
import { useAutoBot } from 'shared/lib/hooks/boosts/useAutoBot';
import { useBoostClaim } from 'shared/lib/hooks/boosts/useBoostClaim';
import { useUpgradeData } from 'shared/lib/hooks/boosts/useUpgradeData';
import { useUpgradeLevel } from 'shared/lib/hooks/boosts/useUpgradeLevel';
import { selectUpgrades } from 'shared/model/boosters/selectors';
import { UpgradeTypes } from 'shared/types/boosters.interfaces';

import { BoostCard } from './BoostCard';
import { BoostsUpgradeLevelModalContent } from './BoostsUpgradeLevelModalContent';
import { UpgradeCard } from './UpgradeCard';

export const BoostsList = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const upgrades = useAppSelector(selectUpgrades);

  const upgradesPremium = getUpgrades({ availableBot: user?.bot_available });

  const [isUnlockModalOpen, handleUnlockModalOpen, handleUnlockModalClose] = useBoolean();
  const [isUnlockAutoBotModalOpen, handleUnlockAutoBotModalOpen, handleUnlockAutoBotModalClose] =
    useBoolean();

  const [{ isUpgradeModalOpen, handleOpenUpgradeModal, handleCloseUpgradeModal }, upgradeData] =
    useUpgradeData();

  const { isLoading, handleLoadPaymentUrl } = useBoostClaim({ onSuccess: handleUnlockModalClose });
  const { isLoadingPaymentAutoBot, handleLoadPaymentUrlAutoBot } = useAutoBot({
    onSuccess: handleUnlockAutoBotModalClose
  });

  const { isLoading: isUpgradeLoading, handleUpgradeLevel } = useUpgradeLevel({
    onSuccess: handleCloseUpgradeModal
  });

  const handleDoublClaim = () => {
    dispatch(asyncBoostActive({ boostType: 'double' }));
  };

  const handleClickBoostClaim = () => {
    if (!user) return;

    if (!user?.bonus_claim_available) {
      handleUnlockModalOpen();
      return;
    }
  };

  const handleUnlockUpgrades = (type: string) => {
    if (!user) return;

    if (!user.bot_available && type === 'auto-bot') {
      handleUnlockAutoBotModalOpen();
    }
  };

  if (!user) return null;

  return (
    <div className="space-y-8 mt-8">
      <Modal
        header={`Upgrade ${upgradeData?.label}`}
        description={`Upgrade ${upgradeData?.label} level - ${upgradeData?.nextLevel?.price} points`}
        isOpened={isUpgradeModalOpen}
        handleClose={handleCloseUpgradeModal}
        height={50}
      >
        <BoostsUpgradeLevelModalContent
          isLoading={isUpgradeLoading}
          userId={user?.id}
          handleClickButton={handleUpgradeLevel}
          data={upgradeData}
        />
      </Modal>
      <Modal
        header="Unlock boost claim"
        description={
          <>
            Unlock ability to increase daily claim. <br /> Unlock it for{' '}
            <span className="text-[#FCB702]">1 star</span>
          </>
        }
        height={50}
        isOpened={isUnlockModalOpen}
        handleClose={handleUnlockModalClose}
      >
        <div className="flex flex-col justify-end h-full">
          {/*  TODO - create correct variants for button - main variant styles below*/}
          <Button
            variant={ButtonVariants.SECONDARY}
            isLoading={isLoading}
            onClick={handleLoadPaymentUrl}
            className="mb-6"
          >
            UNLOCK
          </Button>
        </div>
      </Modal>

      <Modal
        header="Unlock auto bot"
        description={
          <>
            Unlock auto bot permanently. <br /> Unlock it for{' '}
            <span className="text-[#FCB702]">1 star</span>
          </>
        }
        height={50}
        isOpened={isUnlockAutoBotModalOpen}
        handleClose={handleUnlockAutoBotModalClose}
      >
        <div className="flex flex-col justify-end h-full">
          <Button
            variant={ButtonVariants.SECONDARY}
            isLoading={isLoadingPaymentAutoBot}
            onClick={handleLoadPaymentUrlAutoBot}
            className="mb-6"
          >
            UNLOCK
          </Button>
        </div>
      </Modal>

      <WithTitleBlock title="Boosts">
        <BoostCard
          handleClick={handleDoublClaim}
          {...DOUBLE_BOOST_DETAILS}
          usedCount={user?.double_claim}
          isAvailable
        />
        <BoostCard
          {...BOOST_CLAIM_DETAILS}
          usedCount={user?.bonus_claim}
          isAvailable={user?.bonus_claim_available}
          handleClick={handleClickBoostClaim}
        />
      </WithTitleBlock>

      <div className="space-y-8 mt-8">
        <WithTitleBlock title="Upgrades">
          {Object.keys(upgrades || {}).map((upgradeType) => {
            const upgrade = (upgrades || {})[upgradeType as UpgradeTypes];

            const level = user[UPGRADE_LEVEL_KEYS[upgradeType as UpgradeTypes]];

            const icon = UPGRADES_ICONS[upgradeType as UpgradeTypes];

            return (
              <UpgradeCard
                upgrade={upgrade}
                key={upgradeType}
                icon={icon}
                level={level as number}
                handleClick={handleOpenUpgradeModal}
              />
            );
          })}

          {upgradesPremium.map(({ title, description, icon: Icon, button_text, type, aviable }) => (
            <InfoBlock
              key={title}
              title={title}
              description={description}
              leftSideContent={<Icon className="w-[26px] h-[26px]" />}
            >
              {aviable ? (
                <div className="bg-success-linear rounded-full flex justify-center items-center gap-1 w-[76px] leading-5">
                  <span>Used</span>
                  <CheckedIcon />
                </div>
              ) : (
                <Button
                  onClick={() => handleUnlockUpgrades(type)}
                  variant={ButtonVariants.PRIMARY}
                  className="w-20 leading-5"
                >
                  {button_text}
                </Button>
              )}
            </InfoBlock>
          ))}
        </WithTitleBlock>
      </div>
    </div>
  );
};
