import { PropsWithChildren, createContext, useCallback, useEffect, useReducer } from 'react';

import { isNil, throttle } from 'lodash';

import {
  gameWinTileValue,
  mergeAnimationDuration,
  TILE_COUNT_PER_DIMENSION
} from '../constants/constants';
import { Tile } from '../constants/tile.interface';
import gameReducer, { initialState } from '../reducers/game-2048-reducer';

type MoveDirection = 'move_up' | 'move_down' | 'move_left' | 'move_right';

export const Game2048Context = createContext({
  score: 0,
  status: 'ongoing',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  moveTiles: (_: MoveDirection) => {},
  getTiles: () => [] as Tile[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startGame: () => {}
});

export default function GameProvider({ children }: PropsWithChildren) {
  const [gameState, dispatch] = useReducer(gameReducer, initialState);

  const getEmptyCells = () => {
    const results: [number, number][] = [];

    for (let x = 0; x < TILE_COUNT_PER_DIMENSION; x++) {
      for (let y = 0; y < TILE_COUNT_PER_DIMENSION; y++) {
        if (isNil(gameState.board[y][x])) {
          results.push([x, y]);
        }
      }
    }
    return results;
  };

  const appendRandomTile = () => {
    const emptyCells = getEmptyCells();
    if (emptyCells.length > 0) {
      const cellIndex = Math.floor(Math.random() * emptyCells.length);
      const newTile = {
        position: emptyCells[cellIndex],
        value: 2
      };
      dispatch({ type: 'create_tile', tile: newTile });
    }
  };

  const getTiles = () => {
    return gameState.tilesByIds.map((tileId) => gameState.tiles[tileId]);
  };

  const moveTiles = useCallback(
    throttle((type: MoveDirection) => dispatch({ type }), mergeAnimationDuration * 1.05, {
      trailing: false
    }),
    [dispatch]
  );

  const startGame = () => {
    dispatch({ type: 'reset_game' });
    dispatch({ type: 'create_tile', tile: { position: [0, 1], value: 2 } });
    dispatch({ type: 'create_tile', tile: { position: [0, 2], value: 2 } });
  };

  const checkGameState = () => {
    const isWon =
      Object.values(gameState.tiles).filter((t) => t.value === gameWinTileValue).length > 0;

    if (isWon) {
      dispatch({ type: 'update_status', status: 'won' });
      return;
    }

    const { tiles, board } = gameState;

    const maxIndex = TILE_COUNT_PER_DIMENSION - 1;

    for (let x = 0; x < maxIndex; x++) {
      for (let y = 0; y < maxIndex; y++) {
        if (
          isNil(gameState.board[x][y]) ||
          isNil(gameState.board[x + 1][y]) ||
          isNil(gameState.board[x][y + 1])
        ) {
          return;
        }

        if (tiles[board[x][y]].value === tiles[board[x + 1][y]].value) {
          return;
        }

        if (tiles[board[x][y]].value === tiles[board[x][y + 1]].value) {
          return;
        }
      }
    }

    dispatch({ type: 'update_status', status: 'lost' });
  };

  useEffect(() => {
    if (gameState.hasChanged) {
      setTimeout(() => {
        dispatch({ type: 'clean_up' });
        appendRandomTile();
      }, mergeAnimationDuration);
    }
  }, [gameState.hasChanged]);

  useEffect(() => {
    if (!gameState.hasChanged) {
      checkGameState();
    }
  }, [gameState.hasChanged]);

  return (
    <Game2048Context.Provider
      value={{
        score: gameState.score,
        status: gameState.status,
        getTiles,
        moveTiles,
        startGame
      }}
    >
      {children}
    </Game2048Context.Provider>
  );
}
