import { format } from 'date-fns';

import { LuckyGameCardCombinationNumbers } from 'entities/games';
import { LotteryResultCard } from 'entities/lottery-game-results/components/LotteryResultCard';
import {
  Button,
  checkGameResult,
  GameResults,
  LUCKY_GAME_TYPES,
  Modal,
  Typography,
  useAppDispatch,
  useBoolean,
  WithTitleBlock
} from 'shared';
import { DATE_FORMAT_MONTH_DAY } from 'shared/constants/date-formats';
import { TypographyVariants } from 'shared/constants/typography';

import { GameResultModalContent } from './GameResultModalContent';
import { ReactComponent as WinningIcon } from '../../../assets/svg/winning.svg';

interface Props {
  date: string;
  gameData: GameResults[];
}

export const GameResultsBlock = ({ date, gameData }: Props) => {
  const formatDate = format(new Date(date), DATE_FORMAT_MONTH_DAY);

  const [isLotteryResultdModalOpen, handleOpenLotteryResultModal, handleCloseLotteryResultModal] =
    useBoolean();

  const dispatch = useAppDispatch();

  const checkWinningLottery = (id: string) => {
    dispatch(checkGameResult(id));
    handleOpenLotteryResultModal();
  };

  return (
    <div>
      <Modal
        header={`Lottery result`}
        description={`Lottery result description`}
        isOpened={isLotteryResultdModalOpen}
        handleClose={handleCloseLotteryResultModal}
        height={75}
      >
        <GameResultModalContent />
      </Modal>
      <Typography className="mb-2" tag="h2" variant={TypographyVariants.LARGE_TITLE}>
        {formatDate}
      </Typography>
      <div className="bg-[#282B30] rounded-md py-2 px-1 mb-5 space-y-2">
        {gameData?.map(({ id, title, win_combinations }) => (
          <WithTitleBlock key={id} title={`Game ${title}`} childrenClassName="mt-1.5">
            <LotteryResultCard>
              <WinningIcon className="fill-white w-6 h-6" />
              <LuckyGameCardCombinationNumbers
                selectedNumbers={win_combinations}
                bonusNumber={null}
                type={LUCKY_GAME_TYPES.GAME1}
                className="flex-1"
              />
              <Button onClick={() => checkWinningLottery(id)} className="px-3 py-1 rounded-full">
                Check
              </Button>
            </LotteryResultCard>
          </WithTitleBlock>
        ))}
      </div>
    </div>
  );
};
