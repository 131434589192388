import { FC, ReactNode } from 'react';

import cn from 'classnames';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ButtonVariants } from 'shared/constants/button';

import { Button } from './Button';
import { Overlay } from './Overlay';

interface Props {
  isOpened: boolean;
  handleClose: VoidFunction;
  children?: ReactNode;
  modalClassName?: string;
  header?: string;
  description?: string | ReactNode;
  height?: number;
}

export const Modal: FC<Props> = ({
  isOpened,
  handleClose,
  children,
  modalClassName,
  header,
  description,
  height
}) => {
  return createPortal(
    <div
      className={twMerge(
        cn('invisible fixed bottom-0 left-0 h-screen w-full', { 'visible ': isOpened })
      )}
    >
      <div className="w-full h-full">
        <div
          style={{ height: `${height || 95}%` }}
          className={twMerge(
            cn(
              'absolute bottom-0 left-0 min-h-1/2 flex-col p-5 w-full bg-[#282B30] z-50 transition-all duration-300 translate-y-full overflow-hidden rounded-t-0 opacity-0',
              {
                'flex rounded-t-2xl opacity-100 translate-y-0': isOpened
              }
            ),
            modalClassName
          )}
        >
          <Button
            variant={ButtonVariants.TRANSPARENT}
            onClick={handleClose}
            className="absolute top-5 right-5"
          >
            <CloseIcon className="w-6 h-6 [&_path]:fill-white" />
          </Button>
          <div className="text-center mt-6">
            {header && <h3 className="text-2xl text-white font-bold">{header}</h3>}
            {description && <p className="mt-2 text-white">{description}</p>}
          </div>

          <div className="mt-3 h-full overflow-y-scroll">{children}</div>
        </div>
        <Overlay isShown={isOpened} onClick={handleClose} />
      </div>
    </div>,
    document.getElementById('modal') as HTMLElement
  );
};
