export enum UpgradeTypes {
  DAILY_CLAIM = 'dailyClaim',
  REWARD_CLAIM = 'rewardClaim',
  FRIENDS_REWARDS = 'friendsRewards',
  GAMES_REWARDS = 'gamesRewards'
}

export interface BoostActivePayload {
  boostType: string;
  userId?: number;
}

export interface Upgrade {
  id: string;
  title: string;
  type: UpgradeTypes;
  levels: UpgradeLevel[];
}

export interface UpgradeLevel {
  level: number;
  price: number;
  multiplier: number;
}

export interface UpgradeLevelPayload {
  userId: number;
  upgradeId: string;
  label: string;
}

export interface UpgradeData extends Omit<UpgradeLevelPayload, 'userId'> {
  nextLevel: UpgradeLevel;
  levels: UpgradeLevel[];
}
