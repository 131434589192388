import { useState } from 'react';

import { BonusesBlock } from 'features/bonuses';
import { Button, getTelegramApi, selectUser, useAppSelector } from 'shared';
import { ButtonVariants } from 'shared/constants/button';

import { ReactComponent as CopyIcon } from '../assets/svg/copy.svg';
import { ReactComponent as CopySuccessIcon } from '../assets/svg/copy_success.svg';

import 'react-toastify/dist/ReactToastify.css';

export const InvitesBonuses = () => {
  const [invationLink, setInvationLink] = useState('');

  const user = useAppSelector(selectUser);
  const tg = getTelegramApi();

  if (!user) return null;

  const onCopyLink = () => {
    setInvationLink('copy');

    setTimeout(() => setInvationLink(''), 5000);

    navigator.clipboard.writeText(user.referralLink);
  };

  const onInvite = () => {
    tg.openTelegramLink(`https://t.me/share/url?url=${user.referralLink}`);

    setInvationLink('sent');

    setTimeout(() => setInvationLink(''), 5000);
  };

  return (
    <div className="flex flex-col gap-3.5">
      <h2 className="text-2xl font-bold text-center">INVITATION</h2>
      <span className="text-xl font-bold">Invite Friends</span>
      <p className="text-sm text-white">
        Share the referral link with your fiend and community and get more
        <span className="text-[#FFE100]"> $COI</span> to your balance. You’ll get
        <span className="text-[#FFE100]"> 2000 $COI</span> for every invite. For friends with
        Telegram Premium you will get <span className="text-[#FFE100]"> 5000 $COI</span>. After
        successfully fulfilling the conditions of the invitation you can get additional
        <span className="text-[#FFE100]"> $COI</span>.
      </p>
      <div className="flex flex-col gap-1.5">
        <Button
          variant={ButtonVariants.PRIMARY}
          iconClassName={invationLink === 'sent' ? 'w-5 h-5' : ''}
          className="w-full rounded-full gap-1.5 font-semibold py-1"
          icon={invationLink === 'sent' ? CopySuccessIcon : CopyIcon}
          onClick={onInvite}
        >
          {invationLink === 'sent' ? <span>SENT</span> : <span>SEND INVITE</span>}
        </Button>
        <Button
          variant={ButtonVariants.PRIMARY}
          iconClassName={invationLink === 'copy' ? 'w-5 h-5' : ''}
          className="w-full rounded-full gap-1.5 font-semibold py-1"
          icon={invationLink === 'copy' ? CopySuccessIcon : CopyIcon}
          onClick={onCopyLink}
        >
          {invationLink === 'copy' ? <span>COPIED</span> : <span>COPY LINK</span>}
        </Button>
      </div>
      <div>
        <BonusesBlock />
      </div>
    </div>
  );
};
