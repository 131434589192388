export enum LUCKY_GAME_TYPES {
  GAME1 = 'game1',
  GAME2 = 'game2',
  GAME3 = 'game3',
  GAME4 = 'game4'
}

export interface LuckyGame {
  id: string;
  title: string;
  logo_path: string;
  type: LUCKY_GAME_TYPES;
  jackpot: number;
  total_selectable_numbers: number;
  ticket_price: number;
  combinations: LuckyCombination[];
  ended_at: string;
  created_at: string;
  isParticipant: boolean;
}

export interface LuckyCombination {
  id: string;
  selected_combination_numbers: number[];
  bonus_number: number | null;
}

export type PartialLuckyGame = Pick<
  LuckyGame,
  'id' | 'type' | 'ended_at' | 'jackpot' | 'title' | 'logo_path' | 'isParticipant'
>;

export interface LotterySetCombinationPayload {
  id: string;
  combinationId: string;
  combination: number[];
  bonusNumber?: number;
}

export interface LotteryClearCombinationPayload {
  id: string;
  combinationId: string;
}

//Lottery Game Results

export interface GameResults {
  id: string;
  title: string;
  win_combinations: number[];
}

export type GamesResultsResponse = Record<string, GameResults[]>;

interface RewardData {
  reward: number;
  matched_numbers: number;
  id: string;
}

interface LotteryData {
  title: string;
  type: LUCKY_GAME_TYPES;
  jackpot: number;
  win_combinations: number[];
  ended_at: Date;
  rewards: RewardData[];
  id: string;
}

export interface GameResultResponce {
  lottery_id: LotteryData;
  tickets: number;
  combinations: LuckyCombination[];
  winning_amount: number;
  id: string;
}
