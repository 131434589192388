import { differenceInMilliseconds, intervalToDuration } from 'date-fns';

export function calculateTimeLeft(endDate: string) {
  const formattedEndDate = new Date(endDate);
  const formattedStartDate = new Date();

  // Calculate the difference in milliseconds
  const differenceAtMillis = differenceInMilliseconds(formattedEndDate, formattedStartDate);
  if (differenceAtMillis <= 0) {
    return {
      milliseconds: 0,
      fullDate: '00:00:00:00'
    };
  }

  // Get the duration between the two dates
  const duration = intervalToDuration({
    start: new Date(0), // Start from epoch to get a clean duration
    end: new Date(differenceAtMillis)
  });

  // Extracting days, hours, minutes, and seconds
  const days = String(duration.days || 0).padStart(2, '0');
  const hours = String(duration.hours || 0).padStart(2, '0');
  const minutes = String(duration.minutes || 0).padStart(2, '0');
  const seconds = String(duration.seconds || 0).padStart(2, '0');

  // Format the final string as 'dd:HH:mm:ss'
  const fullDate = `${days}:${hours}:${minutes}:${seconds}`;

  return {
    milliseconds: differenceAtMillis,
    fullDate
  };
}
