import { useEffect } from 'react';

import eruda from 'eruda';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AutoBot } from 'features/auto-bot';
import { TabBar } from 'features/tab-bar';
import {
  asyncGetUpgrades,
  asyncGetUser,
  authUser,
  FullScreenLayout,
  getTelegramApi,
  selectIsAuth,
  selectUser,
  setIsFirstLoading,
  useAppDispatch,
  useAppSelector,
  useBoolean
} from 'shared';
import { Loader } from 'shared/components/Loader';
import { LoaderSizes } from 'shared/types/loader.interfaces';

export const PagesCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const isAuth = useAppSelector(selectIsAuth);

  const tg = getTelegramApi();

  useEffect(() => {
    dispatch(setIsFirstLoading(false));
    tg.ready();
    tg.expand();
    eruda.init();
    tg.setHeaderColor('#000');
    tg.disableVerticalSwipes();
    tg.BackButton.onClick(() => navigate(-1));

    dispatch(authUser({ initData: tg?.initData })).then(() => {
      dispatch(
        asyncGetUser({
          id: process.env.REACT_APP_MOCK_USER_ID || 0,
          username: process.env.REACT_APP_MOCK_USER_NAME || '',
          ...tg?.initDataUnsafe?.user
        })
      ).finally(() => {
        setTimeout(() => dispatch(setIsFirstLoading(true)), 100);
      });

      dispatch(asyncGetUpgrades());
    });
  }, [tg, dispatch, navigate]);

  if (isAuth === false)
    return (
      <FullScreenLayout>
        <p>
          Ooops, something went wrong. <br />
          Please, refresh the page.
        </p>
      </FullScreenLayout>
    );

  if (!user) {
    return (
      <FullScreenLayout>
        <Loader size={LoaderSizes.XL} />
      </FullScreenLayout>
    );
  }

  return (
    <div className="h-dvh flex flex-col w-screen text-white font-RedHatDisplay overflow-hidden bg-black">
      <main className="flex flex-col h-full overflow-x-hidden overflow-y-auto remove-scrollbar pt-6 px-7">
        <Outlet />
      </main>
      <footer className="px-7 pt-4 pb-6">
        <TabBar />
      </footer>
      <ToastContainer stacked autoClose={3000} limit={3} />
      <AutoBot user={user} />
    </div>
  );
};
