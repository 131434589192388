import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import usePreviousProps from 'shared/lib/hooks/usePreviousProps';

import {
  mergeAnimationDuration,
  TILE_COUNT_PER_DIMENSION,
  BLOCK_SIZE,
  ITEM_SIZE
} from '../constants/constants';
import { Tile as TileProps } from '../constants/tile.interface';

const getTileColor = (value: number): string => {
  switch (value) {
    case 2:
      return 'bg-[#eee4da] text-[#776e65]';
    case 4:
      return 'bg-[#ede0c8] text-[#776e65]';
    case 8:
      return 'bg-[#f2b179] text-white';
    case 16:
      return 'bg-[#f59563] text-white';
    case 32:
      return 'bg-[#f67c5f] text-white';
    case 64:
      return 'bg-[#f65e3b] text-white';
    case 128:
      return 'bg-[#edcf72] text-white';
    case 256:
      return 'bg-[#edcc61] text-white';
    case 512:
      return 'bg-[#edc850] text-white';
    case 1024:
      return 'bg-[#edc53f] text-white';
    case 2048:
      return 'bg-[#edc22e] text-white';
    default:
      return 'bg-[#cdc1b4] text-[#776e65]';
  }
};

const positionToPixels = (position: number) =>
  (position / TILE_COUNT_PER_DIMENSION) * (BLOCK_SIZE - 16);

export const Tile = ({ position, value }: TileProps) => {
  const [scale, setScale] = useState(1);
  const previousValue = usePreviousProps<number>(value);
  const hasChanged = previousValue !== value;

  useEffect(() => {
    if (hasChanged) {
      setScale(1.1);
      setTimeout(() => setScale(1), mergeAnimationDuration);
    }
  }, [hasChanged]);

  const style = {
    left: positionToPixels(position[0]),
    top: positionToPixels(position[1]),
    transform: `scale(${scale})`,
    zIndex: value,
    width: ITEM_SIZE,
    height: ITEM_SIZE
  };

  return (
    <div
      style={style}
      className={twMerge(
        'absolute flex items-center justify-center text-2xl font-bold rounded-md transition-all m-1',
        getTileColor(value)
      )}
    >
      {value}
    </div>
  );
};
